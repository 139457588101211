import React from "react"

const Footer = () => (
    <footer>
        <a href="https://www.github.com/anuraagshakya">GitHub</a> | 
        <a href="https://www.twitter.com/muchtweetsowow"> Twitter</a> | 
        <a href="https://www.linkedin.com/in/anuraagshakya"> LinkedIn</a><br />
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
    </footer>
)

export default Footer
