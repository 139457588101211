import React from 'react'
import { Link } from 'gatsby'
import "./layout.css"

const NavBar = () => (
    <header>
        <nav>
            <h1>Anuraag Shakya</h1>
            <div className="links">
                <Link to="/">Home</Link>
                <Link to="/portfolio">Portfolio</Link>
                <Link to="/resume">Resume</Link>
            </div>
        </nav>
    </header>    
)

export default NavBar